
























































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { TenantBankAccountDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
@Component({
  name: "TenantBankAccountList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
  },
})
export default class TenantBankAccountList extends Vue {
  queryForm = {};
  isCreate = true;
  isShow = false;
  form: TenantBankAccountDto = {
    id: 0,
    bankAccountCode: undefined,
    bankName: undefined,
    bankAccountName: undefined,
  };
  btnDisabled = false;

  // 获取表数据
  fetchData(params: any) {
    return api.tenantBankAccount.getAll(params);
  }
  // 查看详情
  jumpDetail(row: TenantBankAccountDto) {
    this.$router.push({
      name: "tenantBankAccountDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 新增
  handleCreate() {
    this.isCreate = true;
    this.isShow = true;
    this.btnDisabled = false;
    this.form.id = undefined;
    this.form.bankAccountCode = undefined;
    this.form.bankName = undefined;
    this.form.bankAccountName = undefined;
  }

  // 编辑
  handleEdit(id: number) {
    this.isCreate = false;
    this.btnDisabled = false;
    api.tenantBankAccount.get({ id: id }).then((res: any) => {
      if (res) {
        this.form = { ...res };
      }
    });
    this.isShow = true;
  }
  // 删除
  handleDelete(row: TenantBankAccountDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.tenantBankAccount.delete({ id: row.id }).then((res: any) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.tenantBankAccount
            .update({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.isShow = false;
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        } else {
          await api.tenantBankAccount
            .create({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.isShow = false;
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        }
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  roleRule = {
    bankName: [
      {
        required: true,
        message: "银行名称必填",
        trigger: "blur",
      },
    ],
    bankAccountCode: [
      {
        required: true,
        message: "银行账号必填",
        trigger: "blur",
      },
    ],
    bankAccountName: [
      {
        required: true,
        message: "户名必填",
        trigger: "blur",
      },
    ],
  };
}
